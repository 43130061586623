 /* 
 Hulp Web CSS V1.0 
 Built By Aslan  
 ***************** 
 */


 /* Font Family */
 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 300;
     src: url("./assets/fonts/MatterSQ-Light.woff") format('woff'),
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 400;
     src: url("./assets/fonts/MatterSQ-Regular.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 500;
     src: url("./assets/fonts/MatterSQ-Medium.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 600;
     src: url("./assets/fonts/MatterSQ-SemiBold.woff") format('woff')
 }

 @font-face {
     font-family: 'Matter SQ';
     font-style: normal;
     font-weight: 700;
     src: url("./assets/fonts/MatterSQ-Bold.woff") format('woff')
 }

 /* Body */
 html {
     font-family: "Matter SQ", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif !important;
     -webkit-font-smoothing: antialiased;
     -moz-osx-font-smoothing: grayscale;
     box-sizing: border-box;
     font-style: normal;
     font-weight: 400;
     margin: 0 auto;
     color: #222222;
     font-size: 16px;
 }

 *,
 :after,
 :before {
     margin: 0;
     padding: 0;
     box-sizing: inherit;
     font-style: inherit;
     font-weight: inherit;
     font-family: inherit !important;
     color: inherit;
     line-height: 1.5
 }


 /* Landing Page */
 .uk-nav-bar {
     height: 12vh;
     align-items: center;
     /* padding: 24px 0; */

 }

 .hulp-logo {
     width: 100px;
 }

 .uk-button {
     text-transform: none;
 }

 .uk-button-contact {
     font-weight: 600;
     font-size: 1.25rem;
     line-height: 1.625rem;
     background: #F6F6F6;
     border-radius: 16px;
     padding: 16px 32px;
     color: #004453;
     float: right;
 }

 .uk-button-contact:hover {
     background: #17D4BE;
     color: #004453;
 }

 .mail-btn-icon {
     margin-top: -2px;
     margin-right: 4px;

 }


 .landing-curved-bg {
     background-color: #F6F6F6;
     height: 88vh;
     border-radius: 32px 32px 0px 0px;

 }

 .landing-curved-content {
     padding: 0 7.5rem;
     margin: auto 0;
 }

 .uk-landing-h1 {
     font-size: 5.5rem;
     line-height: 5.5rem;
     font-weight: 700;
     letter-spacing: -0.02em;
     color: #004453;
 }

 .uk-landing-h1 span {
     color: #F6F6F6;
     background: linear-gradient(146.37deg, #4FE07B 1.25%, #17D4BE 86.71%);
     border-radius: 20px;
     padding: 8px 16px;
     width: 80%;

 }

 .uk-landing-subheading {
     font-weight: 400;
     font-size: 1.5rem;
     line-height: 2.125rem;
     letter-spacing: -0.012em;
     color: #004453;
     width: 90%;

 }

 .uk-landing-cta-button {
     font-weight: 600;
     font-size: 1.125rem;
     line-height: 1.625rem;
     color: #FFFFFF;
     background: #004453;
     box-shadow: 0px 25px 20px rgba(0, 0, 0, 0.15);
     border-radius: 16px;
     padding: 20px 40px;
     margin-top: 40px;
 }

 .uk-landing-cta-button:hover {
     color: #17D4BE;
 }

 .uk-landing-meta-text {
     font-weight: 400;
     font-size: 1rem;
     line-height: 1.5rem;
     color: #004453;
 }

 .uk-landing-meta-text span {
     color: #17D4BE;
 }

 .uk-footer-badge {
     position: fixed;
     bottom: 0;
     z-index: 999;
 }

 .landing-container {
     padding: 0 7.5rem;

 }

 .uk-close {
     color: #004453;
 }

 .uk-close:hover {
     color: #17D4BE
 }

 /* Media Queries */
 /* Tablet */
 @media screen and (max-width: 768px) {
     .uk-button-contact {
         font-size: 1.125rem;
     }

     .uk-landing-h1 {
         font-size: 3.75rem;
         line-height: 3.75rem;
         width: 60%;

     }

     .uk-landing-subheading {
         font-size: 1.25rem;
         line-height: 1.625rem;

     }

     .uk-landing-cta-button {
         font-size: 1rem;
         line-height: 1.625rem;
     }

     .landing-curved-content {
         padding: 4rem;
         /* padding-top: 9rem; */
     }

     .hulp-logo {
         width: 80px;
     }
 }

 /* Mobile */
 @media screen and (max-width: 480px) {
     .landing-curved-content {
         padding: 1.5rem;
         padding-top: 3rem;
         margin: 0;

     }

     .uk-landing-h1 {
         font-size: 3rem;
         line-height: 3rem;
         width: 70%;

     }

     .uk-landing-subheading {
         width: 100%;
     }

     .uk-landing-cta-button {
         margin-top: 24px;
     }

     .uk-landing-meta-text {
         font-size: 0.9rem;
     }
 }